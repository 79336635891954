/* Generate:
*  Apply background color to dot
*/
[dir] .timeline-variant-white.timeline-item-fill-border-white[data-v-ffb3c5da] {
  border-color: #ffffff !important;
}
[dir] .timeline-variant-white.timeline-item-fill-border-white[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#ffffff, #fff);
}
[dir] .timeline-variant-white .timeline-item-point[data-v-ffb3c5da] {
  background-color: #ffffff;
}
[dir] .timeline-variant-white .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(255, 255, 255, 0.12);
}
.timeline-variant-white .timeline-item-icon[data-v-ffb3c5da] {
  color: #ffffff;
}
[dir] .timeline-variant-white .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #ffffff;
}
[dir] .timeline-variant-black.timeline-item-fill-border-black[data-v-ffb3c5da] {
  border-color: #000000 !important;
}
[dir] .timeline-variant-black.timeline-item-fill-border-black[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#000000, #fff);
}
[dir] .timeline-variant-black .timeline-item-point[data-v-ffb3c5da] {
  background-color: #000000;
}
[dir] .timeline-variant-black .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(0, 0, 0, 0.12);
}
.timeline-variant-black .timeline-item-icon[data-v-ffb3c5da] {
  color: #000000;
}
[dir] .timeline-variant-black .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #000000;
}
[dir] .timeline-variant-dark.timeline-item-fill-border-dark[data-v-ffb3c5da] {
  border-color: #4b4b4b !important;
}
[dir] .timeline-variant-dark.timeline-item-fill-border-dark[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#4b4b4b, #fff);
}
[dir] .timeline-variant-dark .timeline-item-point[data-v-ffb3c5da] {
  background-color: #4b4b4b;
}
[dir] .timeline-variant-dark .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(75, 75, 75, 0.12);
}
.timeline-variant-dark .timeline-item-icon[data-v-ffb3c5da] {
  color: #4b4b4b;
}
[dir] .timeline-variant-dark .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #4b4b4b;
}
[dir] .timeline-variant-light.timeline-item-fill-border-light[data-v-ffb3c5da] {
  border-color: #f6f6f6 !important;
}
[dir] .timeline-variant-light.timeline-item-fill-border-light[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#f6f6f6, #fff);
}
[dir] .timeline-variant-light .timeline-item-point[data-v-ffb3c5da] {
  background-color: #f6f6f6;
}
[dir] .timeline-variant-light .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(246, 246, 246, 0.12);
}
.timeline-variant-light .timeline-item-icon[data-v-ffb3c5da] {
  color: #f6f6f6;
}
[dir] .timeline-variant-light .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #f6f6f6;
}
[dir] .timeline-variant-primary.timeline-item-fill-border-primary[data-v-ffb3c5da] {
  border-color: #0875d6 !important;
}
[dir] .timeline-variant-primary.timeline-item-fill-border-primary[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#0875d6, #fff);
}
[dir] .timeline-variant-primary .timeline-item-point[data-v-ffb3c5da] {
  background-color: #0875d6;
}
[dir] .timeline-variant-primary .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(8, 117, 214, 0.12);
}
.timeline-variant-primary .timeline-item-icon[data-v-ffb3c5da] {
  color: #0875d6;
}
[dir] .timeline-variant-primary .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #0875d6;
}
[dir] .timeline-variant-secondary.timeline-item-fill-border-secondary[data-v-ffb3c5da] {
  border-color: #a9a9a9 !important;
}
[dir] .timeline-variant-secondary.timeline-item-fill-border-secondary[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#a9a9a9, #fff);
}
[dir] .timeline-variant-secondary .timeline-item-point[data-v-ffb3c5da] {
  background-color: #a9a9a9;
}
[dir] .timeline-variant-secondary .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(169, 169, 169, 0.12);
}
.timeline-variant-secondary .timeline-item-icon[data-v-ffb3c5da] {
  color: #a9a9a9;
}
[dir] .timeline-variant-secondary .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #a9a9a9;
}
[dir] .timeline-variant-success.timeline-item-fill-border-success[data-v-ffb3c5da] {
  border-color: #28c76f !important;
}
[dir] .timeline-variant-success.timeline-item-fill-border-success[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#28c76f, #fff);
}
[dir] .timeline-variant-success .timeline-item-point[data-v-ffb3c5da] {
  background-color: #28c76f;
}
[dir] .timeline-variant-success .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(40, 199, 111, 0.12);
}
.timeline-variant-success .timeline-item-icon[data-v-ffb3c5da] {
  color: #28c76f;
}
[dir] .timeline-variant-success .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #28c76f;
}
[dir] .timeline-variant-info.timeline-item-fill-border-info[data-v-ffb3c5da] {
  border-color: #00cfe8 !important;
}
[dir] .timeline-variant-info.timeline-item-fill-border-info[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#00cfe8, #fff);
}
[dir] .timeline-variant-info .timeline-item-point[data-v-ffb3c5da] {
  background-color: #00cfe8;
}
[dir] .timeline-variant-info .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(0, 207, 232, 0.12);
}
.timeline-variant-info .timeline-item-icon[data-v-ffb3c5da] {
  color: #00cfe8;
}
[dir] .timeline-variant-info .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #00cfe8;
}
[dir] .timeline-variant-warning.timeline-item-fill-border-warning[data-v-ffb3c5da] {
  border-color: #ff9f43 !important;
}
[dir] .timeline-variant-warning.timeline-item-fill-border-warning[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#ff9f43, #fff);
}
[dir] .timeline-variant-warning .timeline-item-point[data-v-ffb3c5da] {
  background-color: #ff9f43;
}
[dir] .timeline-variant-warning .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(255, 159, 67, 0.12);
}
.timeline-variant-warning .timeline-item-icon[data-v-ffb3c5da] {
  color: #ff9f43;
}
[dir] .timeline-variant-warning .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #ff9f43;
}
[dir] .timeline-variant-danger.timeline-item-fill-border-danger[data-v-ffb3c5da] {
  border-color: #ea5455 !important;
}
[dir] .timeline-variant-danger.timeline-item-fill-border-danger[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#ea5455, #fff);
}
[dir] .timeline-variant-danger .timeline-item-point[data-v-ffb3c5da] {
  background-color: #ea5455;
}
[dir] .timeline-variant-danger .timeline-item-point[data-v-ffb3c5da]:before {
  background-color: rgba(234, 84, 85, 0.12);
}
.timeline-variant-danger .timeline-item-icon[data-v-ffb3c5da] {
  color: #ea5455;
}
[dir] .timeline-variant-danger .timeline-item-icon[data-v-ffb3c5da] {
  border: 1px solid #ea5455;
}
.timeline-item[data-v-ffb3c5da] {
  position: relative;
}
[dir=ltr] .timeline-item[data-v-ffb3c5da] {
  padding-left: 2.5rem;
}
[dir=rtl] .timeline-item[data-v-ffb3c5da] {
  padding-right: 2.5rem;
}
[dir] .timeline-item[data-v-ffb3c5da]:not(:last-of-type) {
  padding-bottom: 2rem;
}
[dir=ltr] .timeline-item[data-v-ffb3c5da]:not(:last-of-type) {
  border-left: 1px solid #ebe9f1;
}
[dir=rtl] .timeline-item[data-v-ffb3c5da]:not(:last-of-type) {
  border-right: 1px solid #ebe9f1;
}
.timeline-item[data-v-ffb3c5da]:last-of-type:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 100%;
}
[dir] .timeline-item[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#ebe9f1, #fff);
}
[dir=ltr] .timeline-item[data-v-ffb3c5da]:last-of-type:after {
  left: 0;
}
[dir=rtl] .timeline-item[data-v-ffb3c5da]:last-of-type:after {
  right: 0;
}
.timeline-item .timeline-item-point[data-v-ffb3c5da] {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  z-index: 1;
}
[dir] .timeline-item .timeline-item-point[data-v-ffb3c5da] {
  border-radius: 50%;
}
[dir=ltr] .timeline-item .timeline-item-point[data-v-ffb3c5da] {
  left: -6px;
}
[dir=rtl] .timeline-item .timeline-item-point[data-v-ffb3c5da] {
  right: -6px;
}
.timeline-item .timeline-item-point[data-v-ffb3c5da]:before {
  content: "";
  z-index: 1;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -4px;
  bottom: 0;
}
[dir] .timeline-item .timeline-item-point[data-v-ffb3c5da]:before {
  border-radius: 50%;
}
[dir=ltr] .timeline-item .timeline-item-point[data-v-ffb3c5da]:before {
  left: -4px;
  right: 0;
}
[dir=rtl] .timeline-item .timeline-item-point[data-v-ffb3c5da]:before {
  right: -4px;
  left: 0;
}
.timeline-item .timeline-item-icon[data-v-ffb3c5da] {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  z-index: 1;
  line-height: 2;
}
[dir] .timeline-item .timeline-item-icon[data-v-ffb3c5da] {
  background-color: #fff;
}
[dir=ltr] .timeline-item .timeline-item-icon[data-v-ffb3c5da] {
  left: -15px;
}
[dir=rtl] .timeline-item .timeline-item-icon[data-v-ffb3c5da] {
  right: -15px;
}
.timeline-item .timeline-item-icon .bi[data-v-ffb3c5da] {
  font-size: 1.2rem;
}
[dir] .dark-layout .timeline-item[data-v-ffb3c5da]:last-of-type:after {
  background: linear-gradient(#3b4253, #283046);
}
[dir=ltr] .dark-layout .timeline-item[data-v-ffb3c5da]:not(:last-of-type) {
  border-left-color: #3b4253;
}
[dir=rtl] .dark-layout .timeline-item[data-v-ffb3c5da]:not(:last-of-type) {
  border-right-color: #3b4253;
}
[dir] .dark-layout .timeline-item .timeline-item-icon[data-v-ffb3c5da] {
  background-color: #283046;
}